import type { EmotionCache } from '@emotion/cache';
import { type DehydratedState } from '@tanstack/react-query';
import Layout from 'layout';
import App, { AppContext } from 'next/app';

import styled from '@emotion/styled';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { parseCookies, setCookie } from 'nookies';
import AppThirdPartiesProvider from 'providers/AppThirdPartiesProvider';
import { ToastContainer } from 'react-toastify';

import Login from 'components/Login';
import AppClickTracerProvider from 'providers/AppClickTracerProvider';
import AppMuiThemeProvider from 'providers/AppMuiThemeProvider';
import AppQueryClientProvider from 'providers/AppQueryClientProvider';
import AppReduxProvider from 'providers/AppReduxProvider';
import store from 'states';
import { setGlobalUserIp } from 'states/globalSlice';
import { KARNAMEH_COOKIE_DOMAIN_VALUE } from 'utils/auth';
import { setSentryUserDataFromToken } from 'utils/setSentryUserData';
import { generateUUID } from 'utils/uuid';
import { useComponentWillMountInClient } from '../hooks/useComponentWillMountInClient';
import useTwaApp from '../hooks/useTwaApp';

import 'utils/overrideConsole';

import 'react-toastify/dist/ReactToastify.css';
import 'theme/fontiran.css';
import 'theme/global.css';

export interface MyAppProps extends AppProps {
  rtlEmotionCache?: EmotionCache;
  pageProps: {
    dehydratedState?: DehydratedState;
  };
  viewerId?: string;
  clientIp: string;
}

const Img = styled.img``;

const MyApp = ({
  Component,
  pageProps,
  rtlEmotionCache,
  viewerId,
  clientIp,
}: MyAppProps) => {
  const { query } = useRouter();
  const { setAppReferrerSessionStorage, isTwa } = useTwaApp();

  useComponentWillMountInClient(() => {
    store.dispatch(setGlobalUserIp(clientIp));
    if (query.app_referrer !== 'twa' && !isTwa) {
      setAppReferrerSessionStorage('app');
    } else {
      setAppReferrerSessionStorage('twa');
    }

    setSentryUserDataFromToken();
  });

  return (
    <AppThirdPartiesProvider>
      <AppMuiThemeProvider rtlEmotionCache={rtlEmotionCache}>
        <AppReduxProvider>
          <AppQueryClientProvider state={pageProps?.dehydratedState}>
            <AppClickTracerProvider>
              <Layout>
                <Component {...pageProps} />
                <ToastContainer
                  style={{ fontSize: '0.8rem' }}
                  position="top-center"
                  autoClose={5000}
                  hideProgressBar
                  closeOnClick
                  pauseOnHover
                  draggable
                  theme="light"
                />
                <Img
                  className="yektanet-img"
                  src="https://biscotti.yektanet.com/pixel?id=karnameh"
                />

                <Img
                  className="mediaad-img"
                  src={`https://api.mediaad.org/v2/cookie/match?callback=https://usertrace.karnameh.com/user-trace/v1/yektanet/cookies?viewer_id=${viewerId}`}
                />
                <Login />
              </Layout>
            </AppClickTracerProvider>
          </AppQueryClientProvider>
        </AppReduxProvider>
      </AppMuiThemeProvider>
    </AppThirdPartiesProvider>
  );
};

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const { ctx } = appContext;

  const cookies = parseCookies(ctx);
  const aYearFromNow = new Date();
  aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

  const viewerId = 'viewer_id' in cookies ? cookies.viewer_id : generateUUID();

  const clientIp = ctx.res?.getHeader('x-forwarded-for') as string;

  setCookie(ctx, 'viewer_id', viewerId, {
    path: '/',
    expires: aYearFromNow,
    domain: KARNAMEH_COOKIE_DOMAIN_VALUE,
    sameSite: 'lax',
  });

  return {
    ...appProps,
    viewerId,
    clientIp,
  };
};

export default MyApp;
