import { Box } from '@mui/material';
import useQueryFlags from 'hooks/useQueryFlags';
import BottomNav from 'layout/BottomNav';
import Footer from 'layout/Footer';
import Header from 'layout/Header';
import { emptyPages } from 'layout/data';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useMemo, type FC, type PropsWithChildren } from 'react';
import useTwaApp from '../hooks/useTwaApp';

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useRouter();
  const {
    queryFlags: { hideFooter, hideHeader, hideBottomNav },
  } = useQueryFlags([
    'hideFooter',
    'hideHeader',
    'hideBottomNav',
    'hideBottomNav',
  ]);

  const hasHeaderAndFooter = useMemo(
    () => !emptyPages.some((emptyPage) => new RegExp(emptyPage).test(pathname)),
    [pathname],
  );
  const { isTwa } = useTwaApp();

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
        />

        {/* PWA */}
        <meta name="application-name" content="Karname" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        {/* <meta name="apple-mobile-web-app-title" content={HomeMetaData.title} />
        <meta name="description" content={HomeMetaData.description} /> */}
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-TileColor" content="#00A754" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="theme-color" content="#000000" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/images/logo-website/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/images/logo-website/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/images/logo-website/favicon-16x16.png"
        />
        {/* <link rel="manifest" href="/manifest.json" /> */}
        <link
          rel="mask-icon"
          href="/assets/images/logo-website/safari-pinned-tab.svg"
          color="#00a754"
        />
        <meta name="msapplication-TileColor" content="#00a754" />

        <meta name="theme-color" content="#ffffff" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="https://karname.com" />
        {/* <meta name="twitter:title" content={HomeMetaData.title} />
        <meta name="twitter:description" content={HomeMetaData.description} /> */}
        <meta
          name="twitter:image"
          content="/assets/images/logo-website/android-chrome-192x192.png"
        />
        <meta name="twitter:creator" content="@DavidWShadow" />
        <meta property="og:type" content="website" />
        {/* <meta property="og:title" content={HomeMetaData.title} />
        <meta property="og:description" content={HomeMetaData.description} />
        <meta property="og:site_name" content={HomeMetaData.title} /> */}
        <meta property="og:url" content="https://karname.com" />
        <meta
          property="og:image"
          content="/assets/images/logo-website/android-chrome-192x192.png"
        />
        {/* END PWA */}
      </Head>

      <Box
        component={'main'}
        sx={(t) =>
          pathname === '/'
            ? {
                [t.breakpoints.down('md')]: {
                  pt: '5.5rem',
                },
                [t.breakpoints.up('md')]: {
                  pt: '65px',
                },
              }
            : {}
        }
        id="main"
      >
        {children}
      </Box>
      {hasHeaderAndFooter && !hideFooter && !isTwa ? <Footer /> : null}
      {hasHeaderAndFooter && !hideHeader ? <Header /> : null}
      {hasHeaderAndFooter && !hideBottomNav ? <BottomNav /> : null}
    </>
  );
};

export default Layout;
