var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"v2.21.44"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://802f02dede7c43ebb349b06e383aff85@kar-sentry.karnameh.com/20',

  // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  tracePropagationTargets: [
    //PRODUCTION-DOMAINS
    'api.karnameh.com',
    'aftermarket.kaas.karnameh.com',
    'mycar.kaas.karnameh.com',
    'rest.karnameh.com',
    //Staging DOMAINS
    // 'api.test-5.karnameh.com',
    // 'rest.test-5.karnameh.com',
    // 'api.test-6.karnameh.com',
    // 'rest.test-6.karnameh.com',
    // 'aftermarket-staging.kaas.karnameh.com',
    // 'mycar-staging.kaas.karnameh.com',
  ],

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});
